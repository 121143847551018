@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
}

.mainContainer {
  max-width: 1250px;
  margin: 0 auto;
  padding: 0 1rem;
}
